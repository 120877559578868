import React from 'react';
import './App.css';
import DropZone from './DropZone';
import SetUploader from './SetUploader';
import worker from './worker.js';
import WebWorker from './workerSetup';
import ProcessingCube from './ProcessingCube';
import { timingSafeEqual } from 'crypto';
import Visualizer from './Visualizer';
import Account from './Account';
import LoginDialog from './LoginDialog';
import DeviceLinkDialog from './DeviceLinkDialog';
import queryString from 'query-string';
import qrcode from 'qrcode';

class AppMobileUpload extends React.Component {
  worker = null;

  state = {
    sets: {},
    currentState: 'drop', // drop, analyzing, running, empty
    setsDone: [],
    setsErrored: [],
    qrCode: null,
    activeQrSet: null,
    qrLink: null,
    user: null,
    loginError: null,
    loginShown: false,
    deviceLinkShown: false,
    deviceLinkVerifying: false,
    cloudLinkCode: null,
    linkedSet: null
  }

  propagateDeviceLinkSet(set) {
    if (!this.props.deviceLinkSocket) return;
    
    this.props.deviceLinkSocket.emit('link_message', {
      token: this.props.device.token,
      msg: {
        type: 'set',
        url: set.downloadUrl,
        metadata: set.metaData,
      }
    });
  }

  resetWorker() {
    if (this.worker !== null) {
      this.worker.terminate();
      this.worker = null;
    }
  }

  itemsDropped(items) {
    this.reset();

    this.setState({
      currentState: 'analyzing',
      analyzeData: {totalFiles: items.length, leftFiles: items.length},
      timer: setInterval(() => {
        this.setState({analyzeData: this.state.analyzeData});
      }, 500)
    });

    console.time("Worker start");
    this.worker = new WebWorker(worker);
    console.timeEnd("Worker start");

    this.worker.addEventListener('message', e => {
      const m = e.data;
      console.log(m);
      if ("set" in m) {
        const newSets = Object.assign(this.state.sets);
        newSets[m.set] = m;

        this.setState({sets: newSets, currentSetId: m.set});
      }
      else if ("invalids" in m) {
        clearInterval(this.state.timer);

        if (Object.keys(this.state.sets).length === 0) {
          this.setState({
            currentState: 'empty'
          });
        }
        else {
          this.setState({
            currentState: 'running'
          });
        }
      }
      else if ("stat" in m) {
        this.setState({analyzeData: m.stat});
      }
    });

    this.worker.postMessage({items: items, fullPaths: items.map(i => i.fullPath)});
  }

  reset() {
    if (this.state.timer) clearInterval(this.state.timer);
    this.resetWorker();
    this.setState({
      sets: {},
      currentState: 'drop',
      setsDone: [],
      setsErrored: [],
      qrCode: null,
      analyzeData: null,
      timer: null
    });
  }

  setDone(id, state) {
    if (this.state.setsDone.indexOf(id) === -1) {
      this.state.sets[id].uploadUrl = state.uploadLink;
      this.state.sets[id].downloadUrl = state.downloadLink;
      this.setState({
        setsDone: this.state.setsDone.concat([id]),
        sets: this.state.sets
      });
      this.propagateDeviceLinkSet(this.state.sets[id]);
    }
  }

  setErrored(id) {
    if (this.state.setsErrored.indexOf(id) === -1) {
      this.setState({
        setsErrored: this.state.setsErrored.concat([id])
      });
    }
  }

  suppress(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  render() {
    const setIds = Object.keys(this.state.sets);
    const setUploaders = setIds.map(id => {
      return (<SetUploader id={id} key={id}
        user={this.state.user}
        downloadHost={this.props.uploaderEndpoint + ''}
        endPointUpload={this.props.uploaderEndpoint + '/upload'}
        endPointGetUploadId={this.props.uploaderEndpoint + '/getuploadid'}
        onOpenClick={(qrcode, link) => this.showQRCode(id, qrcode, link)}
        onDone={(state) => this.setDone(id, state)}
        onError={() => this.setErrored(id)}
        set={this.state.sets[id]} />);
    });
    const setCount = setIds.length;
    const allDone = this.state.setsErrored.length + this.state.setsDone.length >= setCount;
    const cancel = this.state.currentState === 'running' && !allDone;
    const activeQrSet = (this.state.sets[this.state.activeQrSet] || null);

    const analyzeData = this.state.analyzeData;
    const filesDone = analyzeData ? analyzeData.totalFiles - analyzeData.leftFiles : 0;
    const inFrame = this.props.framed || (window.location !== window.parent.location && !this.state.linkedSet);

    return (
      <div className={"App" + (this.props.framed ? ' framed' : '')  + ' ' + (inFrame ? ' inframe' : '') + (this.state.linkedSet ? ' linkedset' : '')} onDrag={this.suppress} onDragStart={this.suppress} onDragOver={this.suppress} onDragEnter={this.suppress} onDragLeave={this.suppress} onDragEnd={this.suppress}  onDrop={this.suppress}>
        {!this.props.framed && <header className="App-header">
          {!inFrame && <img style={{display:'block', margin: '2vh auto', maxHeight: '50px', height:'6vh'}} src="mhd-model.svg" />}
          <h1>Medical Image Upload</h1>
        </header>}
        {this.state.linkedSet && <div className="instructions">
          <p>To view this dataset in Medical Imaging XR either scan the code below or open this website on your device.</p>
        </div>}
        {!this.state.linkedSet && <div id="main" className="App-body">
          {this.state.currentState === 'drop' && <div>
            {<div className="instructions">
                      <p>Drag medical imaging data in DICOM format to use on your mobile device below. Your data will be automatically anonymized on your computer before the upload. You can upload several folders at the same time.<br/>
                        {!this.props.framed && "After processing and uploading, scan the QR code with the mobile Medical Imaging XR app or connect your VR device with Device Link (Beta)."}
                        {this.props.framed && "After processing and uploading, the datasets will appear in your VR device."}
                      <br/>
            Read our full data protection information <a href="https://medicalholodeck.com/terms/privacy-policy/index.html" target="_blank">here</a>.</p>
                  </div>}
            <DropZone itemsDropped={(files) => this.itemsDropped(files)} /></div>}
          {this.state.currentState === 'running' && <div>
            {setUploaders}
            <div>{cancel && <button style={{marginTop: '5vh'}} onClick={() => this.reset()}>Cancel</button>}
            {allDone && <button style={{marginTop: '5vh'}} onClick={() => this.reset()}>New Upload</button>}</div>
          </div>}
          {this.state.currentState === 'empty' && <div>
            <div>
            <h2>No DICOM sets found</h2>
            <p>Please select folders containing <strong>DICOM files</strong>.</p>
            <button style={{marginTop: '5vh'}} onClick={() => this.reset()}>New Upload</button></div></div>}
          {this.state.currentState === 'analyzing' && <div style={{textAlign:'center'}}><div>
            {analyzeData &&
              (analyzeData.leftFiles && <h4>Analyzing {analyzeData.totalFiles} files on your computer</h4> ||
              (analyzeData.leftSets && <h4>Anonymizing data on your computer</h4>))}
            {analyzeData.leftFiles && <div className="progress" style={{maxWidth:'400px', margin: 'auto'}}>
              <div className="progresscontainer">
                <div className="progressbar" style={{width: (((filesDone / analyzeData.totalFiles) || 0) * 100) + "%"}}></div>
                <div className="progresstext">{filesDone}/{analyzeData.totalFiles} read</div>
              </div>
            </div>}
            {analyzeData && analyzeData.leftSets && <h4>Building set #{analyzeData.leftSets}<span style={{opacity: (new Date().getSeconds() % 2 ? 0 : 1)}}>.</span></h4>}
            {(setCount > 0) && this.state.sets[this.state.currentSetId] && <div style={{margin:'auto', maxWidth:'20vw', display: 'flex', justifyContent: 'center', height:'20vh'}}>
              <Visualizer setId={this.state.currentSetId} set={this.state.sets[this.state.currentSetId]} />
            </div>}
            <button style={{marginTop: '5vh'}} onClick={() => this.reset()}>Cancel</button></div></div>}
        </div>}
        {this.state.linkedSet && <p className="id" style={{maxWidth: '25em', whiteSpace: 'normal', margin:'auto', marginBottom: '15px', fontSize:'16px'}}>{activeQrSet ? [activeQrSet.metaData.BodyPart, activeQrSet.metaData.Modality, activeQrSet.metaData.depth + ' slices'].filter(d => !!d).join(" – ") : ''}</p>}
      </div>
    );
  }
}

export default AppMobileUpload;
