import React from 'react';
import './ProcessingCube.css'

function ProcessingCube() {
  const spots = () => [0, 1, 2, 3, 4, 5, 6, 7, 8].map(i => {
    return <div key={i} className={"spot" + (Math.random() > 0.95 ? ' out' : '')}></div>;
  });

  return (<div className="processingcontainer">
    <div className="cube">
      <div className="layer">
        {spots()}
      </div>
      <div className="layer">
        {spots()}
      </div>
      <div className="layer">
        {spots()}
      </div>
    </div>
  </div>);
}

export default ProcessingCube;