import React from 'react';

import './DatasetManager.css';

class DatasetManager extends React.Component {
  state = {
    datasets: [],
    editedSets: {},
    savingSets: [],
    previews: {}
  }

  componentWillMount() {
    this.processLinkMessage = this.processLinkMessage.bind(this);

    this.props.deviceLinkSocket.on('link_message', this.processLinkMessage);
    this.updateDatasets();
  }

  componentDidUpdate(pProps) {
    if (this.props.device && (!pProps.device || pProps.device.id !== this.props.device.id)) {
      this.updateDatasets();
    }
  }

  updateDatasets() {
    this.setState({previews: {}});
    // update datasets
    this.props.deviceLinkSocket.emit('link_message', {
      token: this.props.device.token,
      msg: {
        type: 'get_datasets'
      }
    });
  }

  componentWillUnmount() {
    this.props.deviceLinkSocket.off('link_message', this.processLinkMessage);
  }

  processLinkMessage({from, msg}) {
    if (from !== this.props.device.id || !("type" in msg)) return;
    
    if (msg.type === 'datasets') {

      this.state.savingSets.forEach(id => delete this.state.editedSets[id]);

      this.setState({datasets: msg.datasets, editedSets: this.state.editedSets, savingSets: []});

      if (Object.keys(this.state.previews).length == 0) {
        this.props.deviceLinkSocket.emit('link_message', {
          token: this.props.device.token,
          msg: {
            type: 'get_dataset_previews'
          }
        });
      }
    }
    else if (msg.type === 'dataset-previews') {
      this.setState({previews: msg.previews});
    }
  }

  setDataset(dataset, key) {
    this.props.deviceLinkSocket.emit('link_message', {
      token: this.props.device.token,
      msg: {
        type: 'set_dataset',
        dataset: Object.assign({DataSourceId: dataset.DataSourceId}, this.state.editedSets[dataset.DataSourceId] || {})
      }
    });
  }

  deleteDataset(dataset) {
    this.props.deviceLinkSocket.emit('link_message', {
      token: this.props.device.token,
      msg: {
        type: 'delete_dataset',
        dataset: {DataSourceId: dataset.DataSourceId}
      }
    });
  }

  cancelEdit(dataset) {
    delete this.state.editedSets[dataset.DataSourceId];
    this.setState({editedSets: this.state.editedSets});
  }

  saveEdit(dataset) {
    this.setState({savingSets: this.state.savingSets});
    this.state.savingSets.push(dataset.DataSourceId);
    this.setDataset(dataset);
  }

  setName(dataset, name) {
    let parts = (dataset.Description||"").split("\n");
    parts[0] = name;
    var description = parts.join("\n");
    this.state.editedSets[dataset.DataSourceId] = {Description: description};
    this.setState({editedSets: this.state.editedSets});
  }

  render() {
    const datasets = (this.state.datasets || []).map(dataset => {
      let shownDescription = dataset.DataSourceId in this.state.editedSets ? this.state.editedSets[dataset.DataSourceId].Description : (dataset.Description || "");
      return <div className={((this.state.savingSets.indexOf(dataset.DataSourceId) !== -1) ? "saving " : "") + "dataset title-panel"} key={dataset.DataSourceId}>
        <div className="data-preview-image" style={{
          backgroundImage: `url(data:image/png;base64,${this.state.previews[dataset.DataSourceId]})`}}>
        </div>
        <h3>{(dataset.Description || "").split("\n").filter(d => d).join(" - ")}</h3>
        <div className="input">
          <label>Label</label><input style={{maxWidth:'300px'}} type="text" value={shownDescription.split("\n")[0]} onInput={(e) => this.setName(dataset, e.target.value)} />
          {shownDescription != dataset.Description && <button onClick={e => this.saveEdit(dataset)}>Save</button>}
          {shownDescription != dataset.Description && <button onClick={e => this.cancelEdit(dataset)}>Cancel</button>}
        </div>
        <div className="delete">
          <button onClick={() => this.deleteDataset(dataset)}>Delete</button>
        </div>
        {/*<pre>
          {Object.keys(dataset).map(k => `${k}: ${dataset[k]}`).join("\n")}
        </pre>*/}
      </div>
    }).reverse();

    return (
      <div className="datasets" style={{display:'flex', textAlign:'left', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'left'}}>
        {datasets}
      </div>
    );
  }
}

export default DatasetManager;
