
export default () => {
  const _importScripts = self['importScripts'] ? self['importScripts'] : () => {}; // eslint-disable-line no-restricted-globals

	self.addEventListener('message', e => { // eslint-disable-line no-restricted-globals
    if (!e) return;

    let daikon = null, pako = null, fflate = null;
    if (e.data.url) {
      postMessage({daikon: daikon ? 'loaded' : 'notloaded'});
    }
    if (e.data.items) {
      if (!daikon) {
        _importScripts('https://cdn.jsdelivr.net/npm/daikon@1.2.42/release/current/daikon-min.js');
        daikon = self['daikon'];  // eslint-disable-line no-restricted-globals
      }
      if (!fflate) {
        _importScripts('https://unpkg.com/fflate');
        _importScripts('https://cdn.jsdelivr.net/npm/fflate/umd/index.js');
        fflate = self['fflate'];  // eslint-disable-line no-restricted-globals
      }

      let setCount = 0;
      const files = {};
      const zipTransfers = {};
      const sets = {};
      const minMax = {};
      const invalids = [];
      const totalFiles = e.data.items.length;
      let toProcess = totalFiles;

      function Clamp(val, min, max) {
        if (val < min) return min;
        if (val > max) return max;
        return val;
      }

      console.time("readfiles");

      e.data.items.forEach((file, ix) => {
        const reader = new FileReader();
        const fullPath = e.data.fullPaths[ix];

        reader.onload = (e) => {
          toProcess--;

          const arrayBuffer = e.target.result;
          const data = new DataView(arrayBuffer);
          const image = daikon.Series.parseImage(data);

          if (image === null || !image.hasPixelData()) {
            if (file.name.substr(-4) === ".stl") {
              sets["stl-" + Math.random()] = {
                obj: true,
                type: "stl",
                meshData: arrayBuffer,
                name: fullPath,
                files: [{name: fullPath, data: arrayBuffer}]
              };
              setCount++;
              postMessage({stat:{leftFiles: toProcess, totalFiles: totalFiles}});
            }
            else {
              invalids.push(file);
            }
          }
          else {
            let id = image.getSeriesId() + image.getRows() + image.getCols() + image.getImageType().join(";");

            let numberOfFrames = image.getTag(0x0028, 0x0008);
            if (numberOfFrames && numberOfFrames.value && numberOfFrames.value.length && numberOfFrames.value[0] > 1) {
              id = image.getTag(0x0008, 0x0018).value[0];
            }

            if (!(id in sets)) {
              sets[id] = new daikon.Series();
              files[id] = [];
              setCount++;
            }
                
            // gather pixel data &  min/max
            image.interpretedData = image.getInterpretedData(false, true);
  
            files[id].push(file);
            sets[id].addImage(image);

            postMessage({stat:{leftFiles: toProcess, totalFiles: totalFiles}});
          }
          
          if (toProcess === 0) {
            console.timeEnd("readfiles");
            const setIds = Object.keys(sets);

            console.log(setIds);

            if (!setIds.length) {
              postMessage({length: invalids.length, invalids: invalids});
              return;
            }

            let setIdsToTransfer = setIds.length;

            postMessage({stat: {leftSets: setIdsToTransfer}});
            
            const finalizeSet = () => {
              setIdsToTransfer--;
              postMessage({stat: {leftSets: setIdsToTransfer}});

              if (setIdsToTransfer === 0) {
                postMessage({length: invalids.length, invalids: invalids});
              }
            }

            setIds.forEach(id => {
              const setEntry = sets[id];

              if ("files" in setEntry && setEntry.obj === true) {
                // obj/zip files
                const fileMap = {};
                setEntry.files.forEach(setFile => {
                  const filename = setFile.name;
                  fileMap[filename] = new Uint8Array(setFile.data);
                });
                const zipped = fflate.zipSync(fileMap, { level: 1 } );
                const metaData = {
                    Description: setEntry.name,
                    Path:  setEntry.name,
                    Format:  "zip",
                    Data:  "models",
                    ObjectPaths:  Object.keys(fileMap).join("|||")
                };
                postMessage({
                  set: id,
                  length: 1,
                  gzip: false,
                  fileData: zipped,
                  meshData: setEntry.meshData,
                  imageData: false,
                  rawImageData: null,
                  metaData: metaData
                }, [zipped.buffer, setEntry.meshData]);

                finalizeSet();
                return;
              }
              
              const series = sets[id];

              console.time("seriesbuild");
              
              series.buildSeries();

              if (series.images[0].getImagePosition()) {
                // invert ordering
                // this is due to daikon ordering images by imageposition
                // in the reverse order to the mhd dicom viewer
                // this makes also an assumption, that volumetric data contains this tag, whereas 2d data (US/XA,/) does not
                // for 2d case, it should not reverse slices (as it would play multiframe images in reverse)
                series.images.reverse();
              }

              console.timeEnd("seriesbuild");

              console.time("seriescalc");

              const firstImage = series.images[0];
              const lastImage = series.images[series.images.length - 1];
            
              try {
                // here concatenate interpretated data & find out minmax of complete volume
                var elementsPerSlice = firstImage.getCols() * firstImage.getRows() * firstImage.getNumberOfFrames();
                
                // scale values
                var modalityLutType = daikon.Image.getSingleValueSafely(firstImage.getTag(0x0028, 0x3004), 0);
                var rescaleType = daikon.Image.getSingleValueSafely(firstImage.getTag(0x0028, 0x1054), 0);

                var scaleType = !firstImage.getDataScaleIntercept() ? "US" : // unspecified
                  (modalityLutType != null ? modalityLutType : // CR image or such
                      (rescaleType  != null ? rescaleType : // Other images
                          "US"));


                let scaleValue = (minX, maxX, minY, maxY, value) => {
                    return (value - minX) * ((maxY - minY) / (maxX - minX)) + minY;
                };

                // scale grayscale values
                var photometric = firstImage.getPhotometricInterpretation() || "";
                var isGrayscale = photometric.indexOf("MONOCHROME") !== -1;
                if (isGrayscale) {
                  var imageData = new Uint16Array(firstImage.getCols() * firstImage.getRows() * (firstImage.getNumberOfFrames() > 1 ? firstImage.getNumberOfFrames() : series.images.length));
                  var max16bit = 65535;
                
                  var invert = firstImage.getPhotometricInterpretation() == "MONOCHROME1";

                  console.time("minmaxcalc");
                  let max = 0, min = 100000;
                  for (var z = 0; z < series.images.length; z++) {
                    min = Math.min(min, series.images[z].interpretedData.min);
                    max = Math.max(max, series.images[z].interpretedData.max);
                  }
                  console.timeEnd("minmaxcalc");
                  
                  var scaleLower = min;
                  var scaleUpper = max;
  
                  // check for Hounsfield Units
                  if (scaleType == "HU")
                  {
                      scaleLower = -1024;
                      scaleUpper = 3071;
                  }

                  for (var z = 0; z < series.images.length; z++) {
                    var lutPixels = series.images[z].interpretedData.data;
  
                    // scale
                    for (var i = 0; i < elementsPerSlice; i++) {
                      // sign correct
                      var scaledVal = scaleValue(scaleLower, scaleUpper, 0, max16bit, lutPixels[i]);
                      
                      if (invert) {
                        scaledVal = max16bit - scaledVal;
                      }
  
                      imageData[i + z * elementsPerSlice] = scaledVal;
                    }
                  }
                }
                else {
                  // RGB/YBR data
                  var slices = series.images.length || 1;
                  var imageData = new Uint8Array(firstImage.getCols() * firstImage.getRows() * (firstImage.getNumberOfFrames() || 1) * slices * 3);

                  var isYbr = photometric.indexOf("YBR") !== -1;

                  for (var z = 0; z < slices; z++) {  
                    var rawData = series.images[z].interpretedData.data;
                    imageData.set(rawData, firstImage.getCols() * (firstImage.getNumberOfFrames() || 1) * firstImage.getRows() * z * 3 );
                  }
                }

                console.time("metadata");

                const bodyPartTag = lastImage.getTag(0x0018, 0x0015);

                let numberOfFrames = firstImage.getTag(0x0028, 0x0008);
                numberOfFrames = numberOfFrames && numberOfFrames.value && numberOfFrames.value.length && numberOfFrames.value[0] > 1 ? numberOfFrames.value[0] : null;
                const frameRate = firstImage.getTag(0x0008,0x2144);
                const frameTimes = firstImage.getTag(0x0018, 0x1065);

                const processMetadata = {
                  scale_type: scaleType,
                  photometric: isGrayscale ? firstImage.getPhotometricInterpretation() : 'RGB',
                  scale_lower: scaleLower,
                  scale_upper: scaleUpper
                };

                if (processMetadata.photometric === 'YBR_FULL_422') {
                  processMetadata.photometric = 'YBR_FULL'; // daikon already processes 422 part
                }

                if (firstImage.getWindowWidth()) {
                  processMetadata.window_width = firstImage.getWindowWidth();
                  processMetadata.window_center = firstImage.getWindowCenter();
                  let minFilter = (processMetadata.window_center - 0.5 * processMetadata.window_width);
                  let maxFilter = (processMetadata.window_center + 0.5 * processMetadata.window_width);
                  processMetadata.min_filter = scaleValue(scaleLower, scaleUpper, 0, 1, minFilter);
                  processMetadata.max_filter = scaleValue(scaleLower, scaleUpper, 0, 1, maxFilter);
                }

                var imgDirection = firstImage.getImageDirections();
                if (imgDirection) {
                  processMetadata.patientorientationx = `${imgDirection[0]},${imgDirection[1]},${imgDirection[2]}`;
                  processMetadata.patientorientationy = `${imgDirection[3]},${imgDirection[4]},${imgDirection[5]}`;
                }
                var posFirst = firstImage.getImagePosition();
                var posLast = lastImage.getImagePosition();
                if (posFirst && posLast) {
                  processMetadata.patientposfirst = `${posFirst[0]},${posFirst[1]},${posFirst[2]}`;
                  processMetadata.patientposlast = `${posLast[0]},${posLast[1]},${posLast[2]}`;
                }

                const metaData = {
                  ImagePositionPatientFirst: lastImage.getImagePosition() || [-239.0224609375,-413.5224609375,1121.8],
                  ImagePositionPatientLast: firstImage.getImagePosition() || [-239.0224609375,-413.5224609375,-379],
                  SliceThickness: firstImage.getSliceThickness(),
                  ImageOrientation: firstImage.getImageDirections() || [1,0,0,0,1,0],
                  PixelSpacing: firstImage.getPixelSpacing() || [0.955078125,0.955078125],
                  PhotometricInterpretation: firstImage.getPhotometricInterpretation(),
                  BitsAllocated: firstImage.getBitsAllocated(),
                  Modality: firstImage.getModality(),
                  BodyPart: bodyPartTag && bodyPartTag.value && bodyPartTag.value.length ? bodyPartTag.value[0] : "",
                  orientation: firstImage.getOrientation() || "XYZ--+",
                  width: firstImage.getCols(),
                  height: firstImage.getRows(),
                  depth: series.images.length,
                  min: scaleLower,
                  max: scaleUpper,
                  id: id,
                  processMetadata: JSON.stringify(processMetadata),
                  numberOfFrames: numberOfFrames,
                  frameRate: frameRate && frameRate.value && frameRate.value.length ? frameRate.value[0] : null,
                  frameTimes: frameTimes && frameTimes.value && frameTimes.value.length ? frameTimes.value : null
                };

                metaData.PhysicalSize = getPhysicalSize(metaData);
                metaData.ScanDirection = getScanDirection(metaData);

                const newId = Math.floor(Math.random() * 100000 + 10000);

                console.log("Processing", newId, files[id]);

                // mirrorAxises(dataview, metaData);

                console.timeEnd("metadata");
                /*
                let times = [], imageDataCompressed;
                for (let l = 0; l <= 9; l++) {
                  const start = performance.now();
                  imageDataCompressed = fflate.gzipSync(new Uint8Array(imageData.buffer), { level: l } ).buffer;
                  let measure = {level: l, time: performance.now() - start, ratio: (imageData.buffer.byteLength / imageDataCompressed.byteLength)};
                  console.log(measure);
                  times.push(measure);
                }
                console.table(times);
                console.time("compress");
                const imageDataCompressed = fflate.gzipSync(new Uint8Array(imageData.buffer), { level: 1 } ).buffer;
                console.timeEnd("compress");
                */

                console.log(metaData);
                /*
                const imageData16 = new Int16Array(imageData);
                const imageData8 = new ArrayBuffer(imageData.byteLength / 2);

                for (var i = 0; i < imageData16.lengh; i++) {
                  imageData8[i] = Math.floor(imageData16[i] / 4096 * 256);
                }
                */
                console.time("ArrayBuffer transfer");
                postMessage({
                  set: newId, length: series.images.length, gzip: false, imageData: false, rawImageData: imageData, metaData: metaData
                }, [imageData.buffer]);
                console.timeEnd("ArrayBuffer transfer");

                finalizeSet();

                console.timeEnd("seriescalc");
              }
              catch (e) {
                console.error(e);
                finalizeSet();
              }
            });
          }
        };
  
        reader.readAsArrayBuffer(file);
      });

      postMessage({received: e.data.items.length});
    }
  })

  function getScanDirection(set) { 
    let disZ = 0;
    let dis = {
        x: Math.abs(set.ImagePositionPatientLast[0] - set.ImagePositionPatientFirst[0]),
        y: Math.abs(set.ImagePositionPatientLast[1] - set.ImagePositionPatientFirst[1]),
        z: Math.abs(set.ImagePositionPatientLast[2] - set.ImagePositionPatientFirst[2])
    };
    let scanDirection;

    let maxd = Math.max(dis.x, Math.max(dis.y, dis.z));
    if (dis.x > 0 && dis.y > 0 && dis.z > 0) {
        if (dis.x == maxd) {
            disZ = set.ImagePositionPatientLast[0] - set.ImagePositionPatientFirst[0];
            if (disZ > 0)	scanDirection = [1, 0, 0];
            else			scanDirection = [-1, 0, 0];
        }
        else if (dis.y == maxd) {
            disZ = set.ImagePositionPatientLast[1] - set.ImagePositionPatientFirst[1];
            if (disZ > 0)	scanDirection = [0, 1, 0];
            else			scanDirection = [0, -1, 0];
        }
        else {
            disZ = set.ImagePositionPatientLast[2] - set.ImagePositionPatientFirst[2];
            if (disZ > 0)	scanDirection = [0, 0, 1];
            else			scanDirection = [0, 0, -1];
        }
    }
    else if (dis.x > 0 && dis.y > 0) {
        if (dis.y > dis.x) {
            disZ = set.ImagePositionPatientLast[1] - set.ImagePositionPatientFirst[1];
            if (disZ > 0)	scanDirection = [0, 1, 0];
            else			scanDirection = [0, -1, 0];
        }
        else {
            disZ = set.ImagePositionPatientLast[0] - set.ImagePositionPatientFirst[0];
            if (disZ > 0)	scanDirection = [1, 0, 0];
            else			scanDirection = [-1, 0, 0];
        }
    }
    else if (dis.y > 0 && dis.z > 0) {
        if (dis.y > dis.z) {
            disZ = set.ImagePositionPatientLast[1] - set.ImagePositionPatientFirst[1];
            if (disZ > 0)	scanDirection = [0, 1, 0];
            else			scanDirection = [0, -1, 0];
        }
        else {
            disZ = set.ImagePositionPatientLast[2] - set.ImagePositionPatientFirst[2];
            if (disZ > 0)	scanDirection = [0, 0, 1];
            else			scanDirection = [0, 0, -1];
        }
    }
    else if (dis.x > 0 && dis.z > 0) {
        if (dis.x > dis.z) {
            disZ = set.ImagePositionPatientLast[0] - set.ImagePositionPatientFirst[0];
            if (disZ > 0)	scanDirection = [1, 0, 0];
            else			scanDirection = [-1, 0, 0];
        }
        else {
            disZ = set.ImagePositionPatientLast[2] - set.ImagePositionPatientFirst[2];
            if (disZ > 0)	scanDirection = [0, 0, 1];
            else			scanDirection = [0, 0, -1];
        }
    }
    else if (dis.z > 0) {
        disZ = set.ImagePositionPatientLast[2] - set.ImagePositionPatientFirst[2];
        if (disZ > 0)	scanDirection = [0, 0, 1];
        else			scanDirection = [0, 0, -1];
    }
    else if (dis.y > 0) {
        disZ = set.ImagePositionPatientLast[1] - set.ImagePositionPatientFirst[1];
        if (disZ > 0)	scanDirection = [0, 1, 0];
        else			scanDirection = [0, -1, 0];
    }
    else {
        disZ = set.ImagePositionPatientLast[0] - set.ImagePositionPatientFirst[0];
        if (disZ > 0)	scanDirection = [1, 0, 0];
        else			scanDirection = [-1, 0, 0];
    }

    return scanDirection;
  }

  function getPhysicalSize(metadata) {
    var volScale = [metadata.PixelSpacing[0] * metadata.width / 1000, metadata.PixelSpacing[1] * metadata.height / 1000, 1];
    volScale[2] = Math.sqrt(
      Math.pow(metadata.ImagePositionPatientLast[0] - metadata.ImagePositionPatientFirst[0], 2) +
      Math.pow(metadata.ImagePositionPatientLast[1] - metadata.ImagePositionPatientFirst[1], 2) +
      Math.pow(metadata.ImagePositionPatientLast[2] - metadata.ImagePositionPatientFirst[2], 2)
    ) / 1000;
  
    if (volScale[2] === 0) {
      volScale[2] = volScale[0] / metadata.width;
    }

    return volScale;
  }

  function mirrorAxises(volume, metadata) {
    const components = (metadata.PhotometricInterpretation == "RGB") ? 3 : 1;
    const totalVolumeLength = metadata.width * metadata.height * metadata.depth * components;

    if (components === 1) {
      if (metadata.orientation[3] == '+') {
        console.log("Mirroring X");
        let flipRelIndexA, flipIndexB, huB;
        for (let i = 0; i < totalVolumeLength; i++) {
          flipRelIndexA = i % metadata.width;
          if (flipRelIndexA < metadata.width / 2) {
            flipIndexB = (i - flipRelIndexA) + (metadata.width - flipRelIndexA - 1);
            huB = volume[flipIndexB];
            volume[flipIndexB] = volume[i];
            volume[i] = huB;
          }
        }
      }
      if (metadata.orientation[4] == '+') {
        console.log("Mirroring Y");
        let flipRelIndexA, flipIndexB, huB;
        for (let i = 0; i < totalVolumeLength; i++) {
          flipRelIndexA = Math.floor(i / metadata.width);
          if (flipRelIndexA < metadata.height / 2) {
            flipIndexB = (i - flipRelIndexA * metadata.width) + (metadata.height - flipRelIndexA - 1) * metadata.width;
            huB = volume[flipIndexB];
            volume[flipIndexB] = volume[i];
            volume[i] = huB;
          }
        }
      }
      if (metadata.orientation[5] == '+') {
        console.log("Mirroring Z");
        let plane = metadata.width * metadata.height;
        let flipRelIndexA, flipIndexB, huB;
        for (let i = 0; i < totalVolumeLength; i++) {
          flipRelIndexA = Math.floor(i / plane);
          if (flipRelIndexA < metadata.depth / 2) {
            flipIndexB = (i - flipRelIndexA * plane) + (metadata.depth - flipRelIndexA - 1) * plane;
            huB = volume[flipIndexB];
            volume[flipIndexB] = volume[i];
            volume[i] = huB;
          }
        }
      }
    }
  }
}