import React from 'react';
import filesize from 'filesize';
import humanizeDuration from 'humanize-duration';
import './Upload.css';
import ProcessingCube from './ProcessingCube';
import Visualizer from './Visualizer';

function Upload(props) {
  const { percentage, transferred, length, speed, eta } = props.progress;
  const humanizeDurationOptions = {
    languages: {
      shortEn: {
        y: () => 'y',
        mo: () => 'mo',
        w: () => 'w',
        d: () => 'd',
        h: () => 'h',
        m: () => 'm',
        s: () => 's',
        ms: () => 'ms',
      }
    },
    round: true, largest: 2, language: 'shortEn', spacer: '', delimiter: ' '
  }

  return (
    <div className="upload">
      <Visualizer setId={props.id} set={props.set}/>
      <div>
        <h3>{props.set.meshData ? "Mesh" : `${props.set.length} slice${props.set.length === 1 ? '' : 's'}`}</h3>
        <p className='id' title={props.id}>{[props.set.metaData.BodyPart, props.set.metaData.Modality].filter(d => !!d).join(" – ")}</p>
      </div>
      <div style={{margin:'1em 0'}}>
        {(!props.uploadState || props.uploadState === 'none') && <span> Preparing</span>}
          {(props.uploadState === 'uploading') && <span> Uploading</span>}
          {(props.uploadState === 'processing') && <span> Processing</span>}
          {(props.uploadState === 'done') && <span> Done</span>}
          {(props.uploadState === 'error') && <span> Something went wrong</span>}
      </div>
      <div style={{width: '100%'}}>
        {props.uploadState === 'uploading' && props.progress != {} && <div className="progress" style={{width: '90%'}}><div className="progresscontainer">
            <div className="progressbar" style={{width: parseInt(percentage || 0, 10) + "%"}}></div>
            <div className="progresstext">{ filesize(transferred || 0) }/{ filesize(length || 0) }<br/>{ filesize(speed || 0) }/s </div>
          </div>{ humanizeDuration(eta * 1000, humanizeDurationOptions) || 0 }
        </div>
        }
        {(props.uploadState === 'error') && <p><code>{props.error}</code></p>}
        {(props.uploadState === 'done') }
      </div>
    </div>
  );
}

export default Upload;