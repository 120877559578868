
import React from 'react';
const uuid = require('uuid').v4;

class DeviceEmulator extends React.Component {
  state = {
    messages: []
  };

  addMessage (msg) {
    this.state.messages.push(msg);
    this.setState({messages: this.state.messages});
  }

  componentWillMount() {
    const { deviceLinkSocket } = this.props;
    const code = "121312";
    const id = "695c572c-5504-400d-895c-85ff33851177";

    deviceLinkSocket.on('connect', () => {
      this.addMessage("Connected");
      deviceLinkSocket.emit("set_device_identity", {
        "label": "Device Emulator",
        "id": id,
        "type": "standalone",
        "code": code
      });
      this.addMessage("Identified");
    });

    deviceLinkSocket.on('link_message', ({from, msg}) => {
      this.addMessage(`Link message received: ${JSON.stringify(msg)}`);
    });
  }
  
  render() {
    return <div>
      {this.state.messages.map(m => <p>{m}</p>)}
    </div>
  }
}

export default DeviceEmulator;