import React from 'react';
import './App.css';
import './AppDashboard.css';
import AppMobileUpload from './AppMobileUpload';
import LicenseManager from './LicenseManager';
import DatasetManager from './DatasetManager';
import { timingSafeEqual } from 'crypto';
import DeviceLinkDialog from './DeviceLinkDialog';
import queryString from 'query-string';
import qrcode from 'qrcode';

// 1. Linking
// A) link via code
// Join code room, request device id & token
// store device id & token, then B)
// B) link via stored token
// join device id room, verify token,
// then join token room (-> Device joins token room as well)
//
// 2. In token Room
// communicate with device, e.g.:
// - request license status
// - try to activate licenses

class AppDashboard extends React.Component {

  state = {
    activeDevice: "", // "deviceId", 
    devices: {
      /*
      e.g.
      "deviceid": {
        type: "vr", // ar, quest, desktop...
        label: "oculus",
        id: "3232-23232-3232-abc-abc",
        token: token,
        status: "unknown" | "token_invalid" | "linked" | "linking",
        online: false,
      }
      */
    },
    datasets: {}, // map by devices ids
    enterDeviceCode: false,
    deviceLinkVerifying: false,
    currentPanel: 'upload'
  }

  updateDevice({label, type, id, online}) {
    const device = Object.assign({
      status: "unknown"
    }, this.state.devices[id] || {}, {
      type, id, status: "linked", online, label
    });
    this.state.devices[id] = device;
    this.setState({devices: this.state.devices});
  }

  removeDevice(id) {
    delete this.state.devices[id];
    this.setState({devices: this.state.devices});
    this.persistDevices();
  }

  updateDeviceToken(device, token) {
    this.updateDevice(device);
    this.state.devices[device.id].token = token;
    this.setState({devices: this.state.devices});

    this.persistDevices();
  }

  persistDevices() {
    localStorage.setItem('devices', JSON.stringify(this.state.devices));
  }

  componentWillMount() {
    let deviceLinkSocket = this.props.deviceLinkSocket;

    deviceLinkSocket.on('apperror', (msg) => {
      const {error, message} = msg;
      switch (error) {
        case 'device_token_invalid':
          const {token} = msg;
          // find device with token
          Object.keys(this.state.devices).forEach(id => {
            const device = this.state.devices[id];
            if (device.token && device.token === token) {
              delete device.token;
              device.status = "token_invalid";
            }
          });
          break;
        case 'device_code_invalid':
          this.setState({
            deviceLinkVerifying: false,
            enterDeviceCode: true
          });
          break;
        default:
          console.log('apperror', error, message);
      }
    });

    deviceLinkSocket.on('token', ({token, device}) => {
      // new token, send link request
      this.updateDeviceToken(device, token);

      this.setState({
        deviceLinkVerifying: false,
        enterDeviceCode: false
      });
      deviceLinkSocket.emit('link_request', {token});
    });

    deviceLinkSocket.on('device', (device) => {
      // we have connection
      const newlyLinked = !(device.id in this.state.devices) || this.state.devices.status !== 'linked';

      this.updateDevice(device);
      device = this.state.devices[device.id];

      if (device.online && !this.state.activeDevice) {
        this.selectDevice(device.id);
      }
    });

    const storedDevices = localStorage.getItem('devices');
    let devices = {};
    if (storedDevices) {
      devices = JSON.parse(storedDevices);

      Object.keys(devices).forEach(id => {
        const device = devices[id];
        device.online = false;
        device.status = "linking";

        if (device.token) {
          deviceLinkSocket.emit('link_request', {token: device.token});
        }
        else {
          device.status = 'token_invalid';
        }
      });

      this.setState({devices});
    }

    const deviceCount = Object.keys(devices).length;
    switch (deviceCount) {
      case 0:
        this.showDeviceCodeEntry();
        break;
      default:
       break;
    }
  }
  
  componentDidMount() {
    if (this.props.deviceCode) {
      this.linkDevice(this.props.deviceCode);
    }
    if (this.props.screen) {
      this.setState({currentPanel: this.props.screen})
    }
  }

  linkDevice(code) {
    this.setState({
      deviceLinkVerifying: true
    });

    this.props.deviceLinkSocket.emit('get_token', {code});
  }

  unlinkDevice() {
    this.setState({deviceLinkVerifying: false, linkedMhdClient: null, cloudLinkCode: null});
    localStorage.removeItem('cloudlink');
    this.props.deviceLinkSocket.emit('leave room');
  }

  selectDevice(id) {
    const device = this.state.devices[id];
    if (device && device.status === "linked") {
      this.setState({activeDevice: id});
    }
  }

  suppress(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  showDeviceCodeEntry() {
    this.setState({enterDeviceCode: true});
  }
  
  hideDeviceCodeEntry() {
    this.setState({enterDeviceCode: false});
  }

  render() {
    const device = this.state.activeDevice && this.state.devices[this.state.activeDevice];
    const inFrame = window.location !== window.parent.location && !this.state.linkedSet;
    const devices = Object.keys(this.state.devices).map(id => {
      const device = this.state.devices[id];
      return <div onClick={() => {
        this.selectDevice(device.id);
      }} className={"device title-panel " + (this.state.activeDevice == id ? 'selected' : '') + ' ' + (device.online ? 'device-online' : 'device-offline')} key={id} title={id}>
        <div className="header">
          <div>
            <span className="label">{device.label}</span>
            <span className="type">{device.type}</span>
          </div>
          <div className="controls">
            <span className="remove" onClick={() => {
              if (window.confirm(`This removes the link to ${device.label}. Continue?`)) this.removeDevice(device.id);
            }}>Remove</span>
          </div>
        </div>
        {device.status === "linked" && <span className={"state " + (device.online ? 'state-online' : 'state-offline')}>{device.online ? 'Online' : 'Offline'}</span>}
        {device.status === "unknown" && <span className={"state state-unknown"}>Waiting for device</span>}
        {device.status === "linking" && <span className={"state state-linking"}>Connecting to device</span>}
        {device.status === "token_invalid" && <span className={"state state-token-invalid"}>Device Code needed</span>}
      </div>
    });

    return (
      <div className={"App" + (inFrame ? ' inframe' : '') + (this.state.linkedSet ? ' linkedset' : '')} onDrag={this.suppress} onDragStart={this.suppress} onDragOver={this.suppress} onDragEnter={this.suppress} onDragLeave={this.suppress} onDragEnd={this.suppress}  onDrop={this.suppress}>
        <header className="App-header title-panel">
          <img style={{display:'block', maxHeight: '3em', marginRight:'0.6em', height:'6vh'}} src="mhd-model.svg"/>
          <h1>Device Dashboard</h1>
        </header>
        <div className="devices content-panel">
          {devices}
          <div className="device device title-panel adddevice" onClick={() => this.setState({enterDeviceCode: true})}>
            <h3>+ Link device</h3>
          </div>
        </div>
        {device && <div className={"device-dashboard"}>
          <nav className={"panelnav"}>
            <ul>
              {this.props.hideScreens.indexOf("licenses") === -1 && <li><div className={this.state.currentPanel === 'licenses' ? "title-panel" : "content-panel"} onClick={() => this.setState({currentPanel: 'licenses'})}>License Management</div></li>}
              {this.props.hideScreens.indexOf("datasets") === -1 && <li><div className={this.state.currentPanel === 'datasets' ? "title-panel" : "content-panel"} onClick={() => this.setState({currentPanel: 'datasets'})}>Datasets</div></li>}
              {this.props.hideScreens.indexOf("upload") === -1 && <li><div className={this.state.currentPanel === 'upload' ? "title-panel" : "content-panel"} onClick={() => this.setState({currentPanel: 'upload'})}>Upload</div></li>}
            </ul>
          </nav>
          {this.state.currentPanel === 'licenses' && <div className="content-panel">
            <h2>Licenses</h2>
            <LicenseManager
              device={device}
              deviceLinkSocket={this.props.deviceLinkSocket} />
          </div>}
          {this.state.currentPanel === 'datasets' && <div className="content-panel">
            <h2>Datasets</h2>
            <DatasetManager
              device={device}
              deviceLinkSocket={this.props.deviceLinkSocket} />
          </div>}
          {this.state.currentPanel === 'upload' && <div className="upload-panel content-panel">
            <AppMobileUpload
              framed={true}
              device={device}
              uploaderEndpoint={this.props.uploaderEndpoint}
              deviceLinkSocket={this.props.deviceLinkSocket} />
          </div>}
        </div>}
        {<div className={"overlay" + (this.state.enterDeviceCode ? ' shown' : '')}>
          <div className="inner" onClick={(e) => e.stopPropagation()}>
            <DeviceLinkDialog
              currentCode={this.state.cloudLinkCode}
              onEnter={(code) => this.linkDevice(code)}
              onClose={() => this.hideDeviceCodeEntry()}
              unlink={() => this.unlinkDevice()}
              linkedMhdClient={this.state.linkedMhdClient}
              deviceLinkVerifying={this.state.deviceLinkVerifying}
            />
          </div>
          <div onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.hideDeviceCodeEntry(); }} className="screentopright" style={{transform: 'rotateZ(45deg)', lineHeight: '1em', userSelect:'none', margin: '-0.5em 0 0 -0.2em', position:'fixed', pointer: 'default'}}>
            <span style={{ fontSize: '60px'}}>+</span>
          </div>
        </div>}
      </div>
    );
  }
}

export default AppDashboard;
